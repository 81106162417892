<template>
  <img src="../assets/img/loading-img.gif" width="100" height="100" alt="">
</template>

<script>
    export default {
        name: "loading"
    }
</script>

<style scoped>

</style>
